import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyDykJGdTyjrGRqqbENv6Wo3LyRxxc6Bvoo",
  authDomain: "namcor-9b525.firebaseapp.com",
  projectId: "namcor-9b525",
  storageBucket: "namcor-9b525.appspot.com",
  messagingSenderId: "960364304972",
  appId: "1:960364304972:web:ca0a4d62b94b19e98872e9",
  measurementId: "G-8F843DMRG2",
};

const firebaseApp = initializeApp(config);
const firebaseAuthworkerApp = initializeApp(config, "authWorker");

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const authWorker = getAuth(firebaseAuthworkerApp);
