// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import IBlobItem from "../types/IBlobItem";

// THIS IS SAMPLE CODE ONLY - DON'T STORE TOKEN IN PRODUCTION CODE
const sasToken =
  process.env.storagesastoken ||
  "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupix&se=2023-04-14T18:43:20Z&st=2021-11-19T10:43:20Z&spr=https&sig=Q3CMh1Y0JqBJLtBrBcvl6dL5xjbgZgILLfFfDH2XtGo%3D";
const storageAccountName =
  process.env.storageresourcename || "testnamcorcosmosdb"; // Fill string with your Storage resource name
let defaultContainerName = `orders`;
let defaultPrefix = `quotation/`;
// </snippet_package>

// get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
const blobService = new BlobServiceClient(
  `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
);

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
const getBlobsInContainer = async (containerClient: ContainerClient) => {
  const returnedBlobUrls: IBlobItem[] = [];
  // get list of blobs in container
  for await (const blob of containerClient.listBlobsFlat({
    prefix: `${defaultPrefix}/`,
  })) {
    // if image is public, just construct URL
    returnedBlobUrls.push({
      fileName: extractFileName(blob.name),
      fileURL: `https://${storageAccountName}.blob.core.windows.net/${defaultContainerName}/${blob.name}`,
    });
  }
  return returnedBlobUrls;
};

const extractFileName = (fileNameWithPrefix: string): string => {
  return fileNameWithPrefix.slice(defaultPrefix.length + 1);
};
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(
    `${defaultPrefix}/${file.name}`
  );

  // set mimetype as determined from browser with file upload control
  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
    onProgress,
  };
  // upload file
  await blobClient.uploadData(file, options);
};

// <snippet_createBlobInContainer>
const deleteBlobFromContainer = async (
  containerClient: ContainerClient,
  fileName: string
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(
    `${defaultPrefix}/${fileName}`
  );

  // If exists.
  if (await blobClient.exists()) {
    // delete blob from container
    await blobClient.delete();
    return { fileExists: true, deleted: true };
  } else {
    // delete blob from container
    await blobClient.delete();
    return { fileExists: false, deleted: false };
  }
};

// </on progress>
const onProgress = (progress: any) => {
  console.log("progress => ", progress);
};

// </snippet_createBlobInContainer>
// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (
  file: File | null,
  containerName?: string,
  prefix?: string
): Promise<IBlobItem[]> => {
  if (containerName) defaultContainerName = containerName;
  if (prefix) defaultPrefix = prefix;
  if (!file) return [];

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(defaultContainerName);
  await containerClient.createIfNotExists({
    access: "container",
  });
  // upload file
  await createBlobInContainer(containerClient, file);
  // get list of blobs in container
  return getBlobsInContainer(containerClient);
};
// </snippet_uploadFileToBlob>

export const deleteFileFromBlob = async (
  fileName: string,
  containerName?: string,
  prefix?: string
) => {
  if (containerName) defaultContainerName = containerName;
  if (prefix) defaultPrefix = prefix;
  if (!fileName) return [];

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(defaultContainerName);
  await containerClient.createIfNotExists({
    access: "container",
  });

  try {
    // upload file
    await deleteBlobFromContainer(containerClient, fileName);
  } catch (error) {}

  // get list of blobs in container
  return getBlobsInContainer(containerClient);
};

export default uploadFileToBlob;
