import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class ReportOrderDashboardApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
    const y = new Date().getFullYear();
    const m = new Date().getMonth();
    // this.getOverviewOfMonth(m, y);
  }

  // Get overview of a specific month
  async getOverviewOfMonth(month: number, year: number) {
    // first day, last day
    const firstDay = this.firstDate(month, year);
    const lastDay = this.lastDate(month, year);

    // if month overview doesn't exist, create it.
    const open = await this.getOpenOrdersCount(firstDay, lastDay);
    const successful = await this.getClosedOrdersCount(firstDay, lastDay, true);
    const unsuccessful = await this.getClosedOrdersCount(
      firstDay,
      lastDay,
      false
    );

    return { open, successful, unsuccessful };
  }

  // Get open orders.
  private async getOpenOrdersCount(firstDate: number, lastDate: number) {
    const querySpec = {
      query: `SELECT VALUE COUNT(1) FROM c 
              WHERE c.orderSummary.closedOn > @lastDate 
              AND 
              c.orderSummary.createdOn < @lastDate 
              OR 
              c.orderSummary.closedOn = null`,

      parameters: [
        {
          name: "@lastDate",
          value: lastDate,
        },
      ],
    };

    const { resources: count } = await this.dbContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }

  // Get closed orders.
  private async getClosedOrdersCount(
    firstDate: number,
    lastDate: number,
    isSuccessful: boolean
  ) {
    const querySpec = {
      query: `SELECT VALUE COUNT(1) FROM c 
              WHERE c.orderSummary.closedOn > @firstDate 
              AND 
              c.orderSummary.closedOn < @lastDate 
              AND 
              c.orderSummary.isSuccessful = @success`,

      parameters: [
        {
          name: "@firstDate",
          value: firstDate,
        },
        {
          name: "@lastDate",
          value: lastDate,
        },
        {
          name: "@success",
          value: isSuccessful,
        },
      ],
    };

    const { resources: count } = await this.dbContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }

  // Month first day timestamp
  private firstDate(month: number, year: number) {
    return new Date(year, month, 1).getTime();
  }
  // Month last day timestamp
  private lastDate(month: number, year: number) {
    return new Date(year, month + 1, 0).getTime();
  }
}
