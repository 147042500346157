import ISalesTrackerItem from "../types/ISalesTrackerItem";
import { SalesTrackerItem } from "../models/ProductSale";
import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";

export default class SalesTrackerStore extends Store<
  ISalesTrackerItem,
  SalesTrackerItem
> {
  items = new Map<string, SalesTrackerItem>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: ISalesTrackerItem[] = []) {
    runInAction(() => {
      items.forEach((item) => {
        this.items.set(item.id, new SalesTrackerItem(this.store, item));
      });
    });
  }
}
