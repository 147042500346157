const Config = {
  // Endpoints
  endpoint: "https://customer-portal-cosmosdb.documents.azure.com:443/",
  key: "BtXZFsifEY9DCa12injWoTB7d9TK3nES33eJlWrexe8o0ZMVSlam0rocITZ2rqTjz4ZTjpgp3DyDiX96I863OA==",

  // Databases
  databaseId: "Database",

  // Collections
  ordersContainerId: "Orders", //Orders Container
  trackingContainerId: "Tracking", //Tracking Container
  lubesContainerId: "Lubes", //Lubes Container
  fuelsContainerId: "Fuels", //Fuels Container
  fuelPricingContainerId: "Fuels_Pricing", //Fuel Pricing Container
  fuelCartContainerId: "Fuels_Cart", // Fuel cart container
  lubeCartContainerId: "Lubes_Cart", // Lubes cart container
  companyContainerId: "Companies", // Company container
  userRoleContainerId: "User_Roles", // User Roles Container
  mailApiEndpoint: "https://osv.com.na/php/mailer.php?", // Mail API Endpoint

  reportsDatabaseId: "ReportsDatabase",
  reportContainerId: "Sales", //Sales Container

  // Partition keys
  partitionKey: { kind: "Hash", paths: ["/id"] },
};

export default Config;
