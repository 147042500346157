import { lazy, Suspense, useEffect, useState } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AppContext, DeviceWidthContext } from "./shared/functions/Context";
import PrivateRoute from "./shared/auth_guards/PrivateRoute";
import Loading from "./shared/components/Loading";
import AppApi from "./shared/apis/AppApi";
import AppStore from "./shared/stores/AppStore";
import UiStore from "./shared/stores/UiStore";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";

const LoggedInAdmin = lazy(() => import("./logged_in_admin/Main"));
const LoggedInClient = lazy(() => import("./logged_in_client/Main"));
const SignIn = lazy(() => import("./logged_out/sign_in/SignIn"));
const SignUp = lazy(() => import("./logged_out/sign_up/SignUp"));

const store = new AppStore();
const api = new AppApi(store);
const ui = new UiStore();

function App() {
  const [width, setWindowWidth] = useState(0);
  const [isOffline, setIsOffline] = useState(false);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const onlineListener = (e: Event) => {
    setIsOffline(false);
    ui.snackbar.load({
      id: Date.now(),
      message: "Online",
      type: "success",
    });
  };

  const offlineListener = () => {
    setIsOffline(true);
    ui.snackbar.load({
      id: Date.now(),
      message: "Error! Check your internet connection.",
      type: "danger",
      timeoutInMs: 10000,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);

    return () => {};
  }, []);

  return (
    <div>
      <DeviceWidthContext.Provider value={width}>
        <AppContext.Provider value={{ store, api, ui }}>
          <Router>
            <Switch>
              <PrivateRoute path="/a" type="namcor">
                <Suspense fallback={<Loading fullWidth={true} />}>
                  <LoggedInAdmin />
                </Suspense>
              </PrivateRoute>

              <PrivateRoute path="/c" type="client">
                <Suspense fallback={<Loading fullWidth={true} />}>
                  <LoggedInClient />
                </Suspense>
              </PrivateRoute>

              <Route exact path="/sign-in">
                <Suspense fallback={<Loading fullWidth={true} />}>
                  <SignIn />
                </Suspense>
              </Route>

              <Route exact path="/sign-up">
                <Suspense fallback={<Loading fullWidth={true} />}>
                  <SignUp />
                </Suspense>
              </Route>

              <Route exact path="/">
                <Redirect to="/sign-in" />
              </Route>

              <Route exact path="/**">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>

          <SnackbarManager />
        </AppContext.Provider>
      </DeviceWidthContext.Provider>
    </div>
  );
}

export default App;
