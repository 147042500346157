import ILube from "../types/ILube";
import { Lube } from "../models/Lube";
import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";

export default class LubeStore extends Store<ILube, Lube> {
  items = new Map<string, Lube>();
  distinctItems = new Map<string, Lube>();
  importedLubes = new Map<string, Lube>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      importedLubes: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: ILube[] = []) {
    runInAction(() => {
      items.forEach((item) => {
        this.items.set(item.id, new Lube(this.store, item));
        this.distinctItems.set(item.stockCode, new Lube(this.store, item));
      });
    });
  }

  loadLubesFromExcel(items: ILube[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.importedLubes.set(item.id, new Lube(this.store, item))
      );
    });
  }

  removeAllExcelImportedLubes() {
    runInAction(() => {
      this.importedLubes.clear();
    });
  }

  get allExcelImportedLubes() {
    return Array.from(this.importedLubes.values());
  }

  get allDistinctLubes() {
    return Array.from(this.distinctItems.values());
  }
}
