import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import IBlobItem from "../types/IBlobItem";
import ICompany from "../types/ICompany";
import AppApi from "./AppApi";
import uploadFileToBlob, {
  deleteFileFromBlob,
  isStorageConfigured,
} from "./FileUploadApi";

export default class CompanyApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  // <Get all items / first page>
  async getAll(token?: string, limit = 5) {
    if (!token) this.store.company.removeAll();
    const querySpec = {
      query: "SELECT * FROM c",
    };

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ICompany>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();
    this.store.company.load(items);
    return { hasMoreResults, continuationToken };
  }

  // <Get by availability status>
  async getByApproval(status: boolean, token?: string, limit = 5) {
    if (!token) this.store.company.removeAll();
    const querySpec = {
      query: "SELECT * FROM c WHERE c.isApproved = @status",
      parameters: [
        {
          name: "@status",
          value: status,
        },
      ],
    };

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ICompany>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();
    this.store.company.load(items);
    return { hasMoreResults, continuationToken };
  }

  // <Get by Reg>
  async getByReg(reg: string) {
    const querySpec = {
      query: "SELECT * FROM c WHERE  c.id = @reg",
      parameters: [
        {
          name: "@reg",
          value: reg,
        },
      ],
    };
    const { resources: items } = await this.dbContainer.items
      .query<ICompany>(querySpec)
      .fetchNext();
    await this.store.company.load(items);
  }

  // <Create item>
  async create(item: ICompany) {
    try {
      const { resource: createdItem } = await this.dbContainer.items.create(
        item
      );
      if (createdItem) this.store.company.load([createdItem]);
      return { existingResource: false };
    } catch (error) {
      return this.handle409(error);
    }
  }

  handle409(err: any) {
    if (err.code && err.code === 409) {
      return { existingResource: true };
    } else {
      throw err;
    }
  }

  // <Update item>
  async update(item: ICompany) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.company.load([item]);
  }

  // <Delete item>
  async delete(item: ICompany) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    this.store.company.remove(item.id); // Remove from memory
    return result;
  }

  // <Upload founding statement to blob>
  async uploadFileToFoundingStatementBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "companies";
    const prefix = `company_${id}/founding_statements`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.foundingStatements = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      return blobsInContainer;
    } else return [];
  }
  // <Delete founding statements from blob>
  async deleteFileFromFoundingStatementBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "companies";
    const prefix = `company_${id}/founding_statements`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.foundingStatements = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload board of director to blob>
  async uploadFileToBoardOfDirectorsBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "companies";
    const prefix = `company_${id}/board_of_directors`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.boardOfDirectors = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      return blobsInContainer;
    } else return [];
  }
  // <Delete board of directors from blob>
  async deleteFileFromBoardOfDirectorsBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "companies";
    const prefix = `company_${id}/board_of_directors`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.boardOfDirectors = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload banking statement to blob>
  async uploadFileToBankingStatementsBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "companies";
    const prefix = `company_${id}/banking_statements`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.bankingStatements = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      return blobsInContainer;
    } else return [];
  }
  // <Delete banking statements from blob>
  async deleteFileFromBankingStatementBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "companies";
    const prefix = `company_${id}/banking_statements`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.bankingStatements = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload business plans to blob>
  async uploadFileToBusinessPlansBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "companies";
    const prefix = `company_${id}/business_plans`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.businessPlans = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      return blobsInContainer;
    } else return [];
  }
  // <Delete business plans from blob>
  async deleteFileFromBusinessPlansBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "companies";
    const prefix = `company_${id}/business_plans`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.businessPlans = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload IdDocs of members to blob>
  async uploadFileToIdDocsOfMembersBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "companies";
    const prefix = `company_${id}/id_documents`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.idDocs = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      return blobsInContainer;
    } else return [];
  }
  // <Delete business plans from blob>
  async deleteFileFromIdDocsOfMembersBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "companies";
    const prefix = `company_${id}/id_documents`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localCompany = this.store.company.items.get(id);
      if (localCompany) {
        localCompany.complianceDocs.idDocs = blobsInContainer;
        await this.update(localCompany.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // async uploadProgress(file: File | null): Promise<IBlobItem[]> {
  //   if (!file) return [];
  //   // Upload file to storage
  //   if (isStorageConfigured()) {
  //     const blobsInContainer = uploadFileToBlob(file);

  //     //Return the blobsInContainer
  //     return blobsInContainer;
  //   }
  //   return [];
  // }
}
