import axios from "axios";
import { query, collection, where, getDocs } from "firebase/firestore";
import { stringify } from "querystring";
import SECRET from "../constants/Contants";
import { db } from "../database/FirebaseConfig";
import { DB } from "../database/Variables";
import AppStore from "../stores/AppStore";
import { IUser } from "../types/IUser";
import AppApi from "./AppApi";

export default class MailApi {
  API_URI: string;

  constructor(private api: AppApi, private store: AppStore, URI: string) {
    this.API_URI = URI;
  }

  async loadParticipants(companyReg?: string) {
    let to: string[] = [];
    let from: string = "";
    let cc: string[] = [];

    const currentUser = this.store.user.currentUser;
    if (!currentUser) return { to, from, cc };

    const reg = currentUser.companyReg;

    if (!reg) return { to, from, cc };

    from = currentUser.email; // Send from current user

    // From namcor to customer
    if (reg === SECRET.NAMCOR_REG && companyReg) {
      // Reg = Namcor reg
      const $to = await this.loadAllCompanyUsers(companyReg); // Send to customer users
      to = $to.map((user) => user.email);

      const $cc = await this.loadAllCompanyUsers(SECRET.NAMCOR_REG); // Cc namcor's all users, excluding current user
      cc = $cc.map((user) => user.email).filter((email) => email !== from);
      return {
        to,
        from,
        cc,
      };
    }

    //   From customer to namcor
    if (reg !== SECRET.NAMCOR_REG) {
      const $to = await this.loadAllCompanyUsers(SECRET.NAMCOR_REG); // Send to namcor users
      to = $to.map((user) => user.email);

      const $cc = await this.loadAllCompanyUsers(reg); // Cc current company's users, excluding current user
      cc = $cc.map((user) => user.email).filter((email) => email !== from);

      return {
        to,
        from,
        cc,
      };
    }

    return {
      to,
      from,
      cc,
    };
  }

  private async loadAllCompanyUsers(reg: string) {
    const $query = query(
      collection(db, DB.users),
      where("companyReg", "==", reg)
    );
    const querySnapshot = await getDocs($query);
    const users = querySnapshot.docs.map((doc) => {
      return { uid: doc.id, ...doc.data() } as IUser;
    });
    return users;
  }

  // Send
  async sendMail(
    to: string[],
    from: string,
    cc: string[],
    subject: string,
    message: string
  ) {
    const body = {
      from: from,
      to: to.join(", "),
      cc: to.join(", "),
      subject,
      message,
    };

    const uri = `${this.API_URI}${stringify(body)}`;

    axios
      .get(uri)
      .then((response) => {
        //   Snackbar

        console.log("Successful request => ", response.data);
      })
      .catch((error) => {
        //   Snackbar
        console.log(error);
      });
  }

  async sendMailWithoutTo(subject: string, message: string) {
    const { to, from, cc } = await this.loadParticipants();
    await this.sendMail(to, from, cc, subject, message);
  }
}
