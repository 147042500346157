import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";

import ITracking, { Checkpoint, ITrackingStatus } from "../types/ITracking";

export class Tracking implements ITracking {
	id: string;
	orderId: string;
	lastUpdatedOn: number;
	createdOn: number;
	customerName: string;
	customerId: string;
	status: { type: string; value: ITrackingStatus };
	checkpoints: Checkpoint[];
	type: "Tracking" = "Tracking";

	constructor(private store: AppStore, order: ITracking) {
		makeAutoObservable(this);
		this.id = order.id;
		this.orderId = order.orderId;
		this.lastUpdatedOn = order.lastUpdatedOn;
		this.createdOn = order.createdOn;
		this.customerName = order.customerName;
		this.customerId = order.customerId;
		this.status = order.status;
		this.checkpoints = [...order.checkpoints];
	}

	get asJson() {
		const order: ITracking = {
			id: this.id,
			type: "Tracking",
			orderId: this.orderId,
			lastUpdatedOn: this.lastUpdatedOn,
			createdOn: this.createdOn,
			customerName: this.customerName,
			customerId: this.customerId,
			status: this.status,
			checkpoints: [...this.checkpoints],
		};
		return order;
	}
}
