import CartFuelStore from "./CartFuelStore";
import CartLubeStore from "./CartLubeStore";
import CompanyStore from "./CompanyStore";
import FileViewerStore from "./FileViewerStore";
import FuelPricingStore from "./FuelPricingStore";
import FuelStore from "./FuelStore";
import LubeStore from "./LubeStore";
import OrderStore from "./OrderStore";
import ReportStore from "./ReportStore";
import TrackingStore from "./TrackingStore";
import UserRoleStore from "./UserRoleStore";
import UserStore from "./UserStore";

export default class AppStore {
  lube = new LubeStore(this);
  order = new OrderStore(this);
  track = new TrackingStore(this);
  fuel = new FuelStore(this);
  fuelPricing = new FuelPricingStore(this);
  cartFuel = new CartFuelStore(this);
  cartLube = new CartLubeStore(this);
  company = new CompanyStore(this);
  user = new UserStore(this);
  userRole = new UserRoleStore(this);
  fileViewer = new FileViewerStore(this);
  report = new ReportStore(this);
}
