import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import { defaultUser, IUser } from "../types/IUser";
import { User } from "../models/User";

export default class UserStore extends Store<IUser, User> {
  items = new Map<string, User>();
  currentUser: User | null = null;
  loading: boolean = true;

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
      currentUser: true,
      loading: true,
    });
    this.store = store;
  }

  load(items: IUser[]) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.uid, new User(this.store, item))
      );
    });
  }

  loadCurrentUser(item: IUser) {
    const user = { ...defaultUser, ...item };
    runInAction(() => {
      this.currentUser = new User(this.store, user);
    });
  }

  removeCurrentUser() {
    runInAction(() => {
      this.currentUser = null;
    });
  }

  getAllByDepartment(
    departmentName: "Admin" | "User" | "Finance" | "Sales" | "Depot"
  ) {
    const all = this.all;

    return all;
    // if (departmentName === "Admin")
    //   return all.filter((user) => user.role.Admin);
    // if (departmentName === "User")
    //   return all.filter((user) => user.role.User && !user.role.Admin);
    // if (departmentName === "Depot")
    //   return all.filter((user) => user.role.Depot && !user.role.Admin);
    // if (departmentName === "Finance")
    //   return all.filter((user) => user.role.Finance && !user.role.Admin);
    // if (departmentName === "Sales")
    //   return all.filter((user) => user.role.Sales && !user.role.Admin);
    // return [];
  }
}
