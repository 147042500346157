import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ICartLube from "../types/ICartLube";

export class CartLube implements ICartLube {
  id: string;
  customerId: string;
  warehouse: string;
  productId: string;
  stockCode: string;
  description: string;
  quantity: number;
  isSaved: boolean;

  constructor(private store: AppStore, lube: ICartLube) {
    makeAutoObservable(this);
    this.id = lube.id;
    this.stockCode = lube.stockCode;
    this.productId = lube.productId;
    this.description = lube.description;
    this.quantity = lube.quantity;
    this.customerId = lube.customerId;
    this.warehouse = lube.warehouse;
    this.isSaved = true;
  }

  get asJson(): ICartLube {
    return {
      id: this.id,
      stockCode: this.stockCode,
      warehouse: this.warehouse,
      productId: this.productId,
      description: this.description,
      quantity: this.quantity,
      customerId: this.customerId,
    };
  }
}
