import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IFuelPricing from "../types/IFuelPricing";

export class FuelPricing implements IFuelPricing {
	id: string;
	customerName: string;
	customerId: string;
	bunkerSize: string;
	description: string;
	prepareBy: string;
	createdAt: number;
	reviewer: string;
	reviewedBy: string;
	reviewedAt: number;
	isReviewed: boolean;
	purchasePrice: number;
	addNAMCORMargin: number;
	lessRebate: number;
	addTransport: number;
	addREMHandling: number;
	addOvertime: number;
	addNAMCORStaffCost_Salaries: number;
	sellingPrice: number;
	sellingPriceCPL: number;
	lessCostSupplierWhenPurchased: number;
	grossProfit: number;
	lessTransport: number;
	lessREMHandling: number;
	addNAMOD: number;
	lessNAMCORStaffCost_Salaries: number;
	profit: number;
	grossProfitMargin: number;

	constructor(private store: AppStore, fuelPricing: IFuelPricing) {
		makeAutoObservable(this);
		this.id = fuelPricing.id;
		this.customerId = fuelPricing.customerId;
		this.customerName = fuelPricing.customerName;
		this.description = fuelPricing.description;
		this.bunkerSize = fuelPricing.bunkerSize;
		this.prepareBy = fuelPricing.prepareBy;
		this.reviewer = fuelPricing.reviewer;
		this.reviewedBy = fuelPricing.reviewedBy;
		this.reviewedAt = fuelPricing.reviewedAt;
		this.isReviewed = fuelPricing.isReviewed;
		this.createdAt = fuelPricing.createdAt;
		this.purchasePrice = fuelPricing.purchasePrice;
		this.addNAMCORMargin = fuelPricing.addNAMCORMargin;
		this.lessRebate = fuelPricing.lessRebate;
		this.addTransport = fuelPricing.addTransport;
		this.addREMHandling = fuelPricing.addREMHandling;
		this.addOvertime = fuelPricing.addOvertime;
		this.addNAMCORStaffCost_Salaries = fuelPricing.addNAMCORStaffCost_Salaries;
		this.sellingPrice = fuelPricing.sellingPrice;
		this.sellingPriceCPL = fuelPricing.sellingPriceCPL;
		this.lessCostSupplierWhenPurchased =
			fuelPricing.lessCostSupplierWhenPurchased;
		this.grossProfit = fuelPricing.grossProfit;
		this.lessTransport = fuelPricing.lessTransport;
		this.lessREMHandling = fuelPricing.lessREMHandling;
		this.addNAMOD = fuelPricing.addNAMOD;
		this.lessNAMCORStaffCost_Salaries =
			fuelPricing.lessNAMCORStaffCost_Salaries;
		this.profit = fuelPricing.profit;
		this.grossProfitMargin = fuelPricing.grossProfitMargin;
	}

	get asJson() {
		const fuelPricing: IFuelPricing = {
			id: this.id,
			createdAt: Date.now(),
			customerName: this.customerName,
			customerId: this.customerId,
			bunkerSize: this.bunkerSize,
			description: this.description,
			prepareBy: this.prepareBy,
			reviewer: this.reviewer,
			reviewedBy: this.reviewedBy,
			reviewedAt: this.reviewedAt,
			isReviewed: false,
			purchasePrice: this.purchasePrice,
			addNAMCORMargin: this.addNAMCORMargin,
			lessRebate: this.lessRebate,
			addTransport: this.addTransport,
			addREMHandling: this.addREMHandling,
			addOvertime: this.addOvertime,
			addNAMCORStaffCost_Salaries: this.addNAMCORStaffCost_Salaries,
			sellingPrice: this.sellingPrice,
			sellingPriceCPL: this.sellingPriceCPL,
			lessCostSupplierWhenPurchased: this.lessCostSupplierWhenPurchased,
			grossProfit: this.grossProfit,
			lessTransport: this.lessTransport,
			lessREMHandling: this.lessREMHandling,
			addNAMOD: this.addNAMOD,
			lessNAMCORStaffCost_Salaries: this.lessNAMCORStaffCost_Salaries,
			profit: this.profit,
			grossProfitMargin: this.grossProfitMargin,
		};
		return fuelPricing;
	}
}
