import IFuel from "../types/IFuel";
import { Fuel } from "../models/Fuel";
import Store from "./Store";
import AppStore from "./AppStore";
import { makeObservable, runInAction } from "mobx";

export default class FuelStore extends Store<IFuel, Fuel> {
  items = new Map<string, Fuel>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: IFuel[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Fuel(this.store, item))
      );
    });
  }
}
