import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ICompany, {
	ICompanyComplianceDocuments,
	ICompanyProfile,
} from "../types/ICompany";

export class Company implements ICompany {
	id: string;
	isApproved: boolean;
	createdOn: number;
	availableCredit: number;
	companyProfile: ICompanyProfile;
	complianceDocs: ICompanyComplianceDocuments;

	constructor(private store: AppStore, company: ICompany) {
		makeAutoObservable(this);
		this.id = company.id;
		this.isApproved = company.isApproved;
		this.createdOn = company.createdOn;
		this.availableCredit = company.availableCredit;
		this.companyProfile = company.companyProfile;
		this.complianceDocs = company.complianceDocs;
	}

	get asJson() {
		const company: ICompany = {
			id: this.id,
			isApproved: this.isApproved,
			createdOn: this.createdOn,
			availableCredit: this.availableCredit,
			companyProfile: this.companyProfile,
			complianceDocs: this.complianceDocs,
		};
		return company;
	}
}
