import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IBlobItem from "../types/IBlobItem";

export class BlobItemModel implements IBlobItem {
  fileName: string;
  fileURL: string;

  constructor(private store: AppStore, blob: IBlobItem) {
    makeAutoObservable(this);
    this.fileName = blob.fileName;
    this.fileURL = blob.fileURL;
  }

  get asJson() {
    const blob: IBlobItem = {
      fileName: this.fileName,
      fileURL: this.fileURL,
    };
    return blob;
  }
}
