import { makeAutoObservable } from "mobx";
import ISalesTrackerItem from "../types/ISalesTrackerItem";
import AppStore from "../stores/AppStore";

export class SalesTrackerItem implements ISalesTrackerItem {
  id: string;
  product: string;
  salesCategory: string;
  customer: string;
  quantity: number;
  quantityType: "Volume" | "Units";
  warehouse: string;
  createdOn: number;

  constructor(private store: AppStore, product: ISalesTrackerItem) {
    makeAutoObservable(this);
    this.id = product.id;
    this.product = product.product;
    this.warehouse = product.warehouse;
    this.quantity = product.quantity;
    this.salesCategory = product.salesCategory;
    this.customer = product.customer;
    this.quantityType = product.quantityType;
    this.createdOn = product.createdOn;
  }

  get asJson(): ISalesTrackerItem {
    return {
      id: this.id,
      product: this.product,
      warehouse: this.warehouse,
      quantity: this.quantity,
      salesCategory: this.salesCategory,
      customer: this.customer,
      quantityType: this.quantityType,
      createdOn: this.createdOn,
    };
  }
}
