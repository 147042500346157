import { makeAutoObservable } from "mobx";
import ILube from "../types/ILube";
import AppStore from "../stores/AppStore";

export class Lube implements ILube {
  id: string;
  stockCode: string;
  warehouse: string;
  description: string;
  sellingPrice: number;
  discountedPrice: number;
  packSize: number;
  quantity: number;
  isAvailable: boolean;
  createdAt: number;

  constructor(private store: AppStore, lube: ILube) {
    makeAutoObservable(this);
    this.id = lube.id;
    this.stockCode = lube.stockCode;
    this.warehouse = lube.warehouse;
    this.description = lube.description;
    this.sellingPrice = lube.sellingPrice;
    this.discountedPrice = lube.discountedPrice;
    this.packSize = lube.packSize;
    this.quantity = lube.quantity;
    this.isAvailable = lube.isAvailable;
    this.createdAt = lube.createdAt;
  }

  get asJson(): ILube {
    return {
      id: this.id,
      stockCode: this.stockCode,
      warehouse: this.warehouse,
      description: this.description,
      sellingPrice: this.sellingPrice,
      discountedPrice: this.discountedPrice,
      packSize: this.packSize,
      quantity: this.quantity,
      isAvailable: this.isAvailable,
      createdAt: this.createdAt,
    };
  }
}
