import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import IFuelPricing from "../types/IFuelPricing";
import AppApi from "./AppApi";

export default class FuelPricingApi {
  dbContainer: Container;

  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, limit = 10) {
    if (!token) this.store.fuelPricing.removeAll();
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .readAll<IFuelPricing>({ maxItemCount: limit, continuationToken: token })
      .fetchNext();
    this.store.fuelPricing.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getByReviewal(isReviewed: boolean, token?: string, limit = 10) {
    if (!token) this.store.fuelPricing.removeAll();
    const querySpec = {
      query: "SELECT * FROM c WHERE  c.isReviewed = @isReviewed",
      parameters: [
        {
          name: "@isReviewed",
          value: isReviewed,
        },
      ],
    };
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<IFuelPricing>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();

    this.store.fuelPricing.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string) {
    const { resource: item } = await this.dbContainer
      .item(id)
      .read<IFuelPricing>();
    if (item) this.store.fuelPricing.load([item]);
  }

  // </CreateItem>
  async create(item: IFuelPricing) {
    const { resource: createdItem } = await this.dbContainer.items.create(item);
    if (createdItem) this.store.fuelPricing.load([createdItem]);
  }

  // </UpdateItem>
  async update(item: IFuelPricing) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id)
      .replace(item);
    if (updatedItem) this.store.fuelPricing.load([updatedItem]);
  }

  // </DeleteItem>
  async delete(item: IFuelPricing) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database

    this.store.fuelPricing.remove(item.id); // Remove from memory
  }
}
