import Store from "./Store";
import AppStore from "./AppStore";
import { makeObservable, runInAction } from "mobx";
import ICartFuel from "../types/ICartFuel";
import { CartFuel } from "../models/CartFuel";

export default class CartFuelStore extends Store<ICartFuel, CartFuel> {
  items = new Map<string, CartFuel>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: ICartFuel[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new CartFuel(this.store, item))
      );
    });
  }
}
