import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import ILube from "../types/ILube";
import AppApi from "./AppApi";

export default class LubeApi {
  dbContainer: Container;

  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, limit = 10) {
    if (!token) this.store.lube.removeAll();
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .readAll<ILube>({
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();
    this.store.lube.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getAllDistinct(token?: string, limit = 10) {
    if (!token) this.store.lube.removeAll();
    const querySpec = {
      query:
        "SELECT DISTINCT c.stockCode, c.description FROM Lubes c ORDER BY c.description",
      continuationToken: token,
    };
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ILube>(querySpec, {
        maxItemCount: limit,
        // partitionKey: "HFO",
        continuationToken: token,
      })
      .fetchNext();

    const mappedItems = items.map((item) => {
      return {
        ...item,
        id: item.stockCode,
        sellingPrice: 0,
        discountedPrice: 0,
      };
    });

    this.store.lube.load(mappedItems);
    return { hasMoreResults, continuationToken };
  }

  async getByAvailability(availability: boolean, token?: string, limit = 10) {
    if (!token) this.store.lube.removeAll();
    const querySpec = {
      query: "SELECT * FROM c WHERE c.isAvailable = @availability",
      parameters: [
        {
          name: "@availability",
          value: availability,
        },
      ],
    };
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ILube>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();
    this.store.lube.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string) {
    const { resource: item } = await this.dbContainer.item(id).read<ILube>();
    if (item) this.store.lube.load([item]);
  }

  async create(item: ILube) {
    const { resource: createdItem } = await this.dbContainer.items.create(item);
    if (createdItem) this.store.lube.load([createdItem]);
  }

  async update(item: ILube) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.lube.load([updatedItem]);
  }

  async delete(item: ILube) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    this.store.lube.remove(item.id); // Remove from memory
  }
}
