import { CosmosClient } from "@azure/cosmos";
import Config from "../database/Config";
import AppStore from "../stores/AppStore";
import AuthApi from "./AuthApi";
import CartFuelApi from "./CartFuelApi";
import CartLubeApi from "./CartLubeApi";
import CompanyApi from "./CompanyApi";
import DashboardApi from "./DashboardApi";
import FuelApi from "./FuelApi";
import FuelPricingApi from "./FuelPricingApi";
import LubeApi from "./LubeApi";
import MailApi from "./MailApi";
import OrderApi from "./OrderApi";
import ReportApi from "./ReportApi";
import TrackingApi from "./TrackingApi";
import UserRoleApi from "./UserRoleApi";

const {
  key,
  endpoint,
  databaseId,
  reportsDatabaseId,
  lubesContainerId,
  fuelsContainerId,
  fuelPricingContainerId,
  ordersContainerId,
  trackingContainerId,
  fuelCartContainerId,
  lubeCartContainerId,
  companyContainerId,
  userRoleContainerId,
  reportContainerId,
  mailApiEndpoint,
} = Config;

export default class AppApi {
  // <CreateClientObjectDatabaseContainer>
  private client = new CosmosClient({ endpoint, key });

  // Connect to database
  private database = this.client.database(databaseId);
  private reportsDatabase = this.client.database(reportsDatabaseId);

  // Connect to containers
  private lubeContainer = this.database.container(lubesContainerId);
  private fuelContainer = this.database.container(fuelsContainerId);
  private fuelPricingContainer = this.database.container(
    fuelPricingContainerId
  );
  private fuelCartContainer = this.database.container(fuelCartContainerId);
  private lubeCartContainer = this.database.container(lubeCartContainerId);
  private orderContainer = this.database.container(ordersContainerId);
  private userRoleContainer = this.database.container(userRoleContainerId);
  private trackingContainer = this.database.container(trackingContainerId);
  private companyContainer = this.database.container(companyContainerId);
  private reportContainer = this.reportsDatabase.container(reportContainerId);

  // <APIs>
  lube: LubeApi;
  fuel: FuelApi;
  cartFuel: CartFuelApi;
  cartLube: CartLubeApi;
  fuelPricing: FuelPricingApi;
  order: OrderApi;
  track: TrackingApi;
  auth: AuthApi;
  userRole: UserRoleApi;
  company: CompanyApi;
  mail: MailApi;
  dashboard: DashboardApi;
  report: ReportApi;

  constructor(private store: AppStore) {
    // </CreateClientObjectDatabaseContainer>
    this.lube = new LubeApi(this, store, this.lubeContainer);
    this.fuel = new FuelApi(this, store, this.fuelContainer);
    this.cartFuel = new CartFuelApi(this, store, this.fuelCartContainer);
    this.cartLube = new CartLubeApi(this, store, this.lubeCartContainer);
    this.fuelPricing = new FuelPricingApi(
      this,
      store,
      this.fuelPricingContainer
    );
    this.order = new OrderApi(this, store, this.orderContainer);
    this.track = new TrackingApi(this, store, this.trackingContainer);
    this.auth = new AuthApi(this, store);
    this.userRole = new UserRoleApi(this, store, this.userRoleContainer);
    this.company = new CompanyApi(this, store, this.companyContainer);
    this.mail = new MailApi(this, store, mailApiEndpoint);
    this.report = new ReportApi(this, store, this.reportContainer);
    this.dashboard = new DashboardApi(this, store, {
      lubesDBContainer: this.lubeContainer,
      fuelsDBContainer: this.fuelContainer,
      pricingDBContainer: this.fuelPricingContainer,
      ordersDBContainer: this.orderContainer,
    });
  }
}
