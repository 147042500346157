export const defaultUserRole: IUserRole = {
  id: "",
  roleName: "",
  lube: {
    canView: true,
    canModify: true,
    canUpdateAvailability: false,
  },
  fuel: {
    canView: true,
    canModify: true,
    canUpdateAvailability: false,
  },
  fuelPricing: {
    canView: true,
    canModify: false,
    canReview: false,
  },
  tracking: {
    canView: true,
    canModify: true,
  },
  customer: {
    canView: true,
    canModify: false,
    canUpdateCredit: false,
  },
  team: {
    canView: true,
    canModify: false,
  },
  order: {
    canView: true,
    canModify: true,
    requestRole: {
      canView: true,
      canModify: true,
    },
    quoteRole: {
      canView: true,
      canModify: true,
    },
    purchaseOrderRole: {
      canView: true,
      canModify: true,
    },
    salesOrderRole: {
      canView: true,
      canModify: true,
    },
    deliveryNoteRole: {
      canView: true,
      canModify: true,
    },
    invoiceRole: {
      canView: true,
      canModify: true,
    },
    proofOfPaymentRole: {
      canView: true,
      canModify: true,
    },
  },
};

export interface IAccessType {
  read: boolean;
  readWrite: boolean;
}

export interface IRole {
  canView: boolean;
  canModify: boolean;
}

export interface ILubeRole extends IRole {
  canUpdateAvailability: boolean;
}

export interface IFuelRole extends IRole {
  canUpdateAvailability: boolean;
}

export interface IFuelPricingRole extends IRole {
  canReview: boolean;
}

export interface ITrackingRole extends IRole {}

export interface ICustomerRole extends IRole {
  canUpdateCredit: boolean;
}

export interface ITeamRole extends IRole {}

export interface IOrderRole extends IRole {
  requestRole: IRole;
  quoteRole: IRole;
  purchaseOrderRole: IRole;
  salesOrderRole: IRole;
  deliveryNoteRole: IRole;
  invoiceRole: IRole;
  proofOfPaymentRole: IRole;
}

export interface IUserRole {
  id: string;
  roleName: string;
  lube: ILubeRole;
  fuel: IFuelRole;
  fuelPricing: IFuelPricingRole;
  tracking: ITrackingRole;
  customer: ICustomerRole;
  team: ITeamRole;
  order: IOrderRole;
}
