export const defaultTracking: ITracking = {
  id: "",
  orderId: "",
  lastUpdatedOn: Date.now(),
  createdOn: Date.now(),
  type: "Tracking",
  customerName: "",
  customerId: "",
  status: {
    type: "warning",
    value: "Processing",
  },
  checkpoints: [
    {
      name: "Processing",
      estimatedArrivalTime: "",
      arrivedOn: Date.now(),
      isComplete: false,
      isActive: true,
    },
  ],
};

export const defaultTrackingCheckpoint: Checkpoint = {
  name: "",
  estimatedArrivalTime: "",
  isComplete: false,
  isActive: false,
};

export interface Checkpoint {
  name: string;
  estimatedArrivalTime: string;
  arrivedOn?: number;
  isComplete?: boolean;
  isActive?: boolean;
}

export type ITrackingStatus =
  | "Processing"
  | "In Transit"
  | "Delivered"
  | "Archived";

export default interface ITracking {
  id: string;
  orderId: string;
  type: "Tracking";
  createdOn: number;
  lastUpdatedOn: number;
  customerName: string;
  customerId: string;
  status: {
    type: "red" | "green" | "blue" | "warning" | "archived" | string;
    value: ITrackingStatus;
  };
  checkpoints: Checkpoint[];
}
