import { makeObservable } from "mobx";
import { FuelPricing } from "../models/FuelPricing";
import IFuelPricing from "../types/IFuelPricing";
import AppStore from "./AppStore";
import Store from "./Store";

export default class FuelPricingStore extends Store<IFuelPricing, FuelPricing> {
  items = new Map<string, FuelPricing>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: IFuelPricing[] = []) {
    items.forEach((item) =>
      this.items.set(item.id, new FuelPricing(this.store, item))
    );
  }
}
