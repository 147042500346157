import { defaultUserRole, IUserRole } from "./IUserRole";

export const defaultUser: IUser = {
  uid: "",
  firstName: "",
  lastName: "",
  email: "",
  companyReg: "namcor",
  companyName: "Namcor",
  role: defaultUserRole,
};

export interface IUser {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  companyReg: string;
  companyName: string;
  role: IUserRole;
}
