import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import ICompany from "../types/ICompany";
import { Company } from "../models/Company";

export default class CompanyStore extends Store<ICompany, Company> {
  items = new Map<string, Company>();
  currentCompany: Company | null = null;
  loading: boolean = true;

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
      loading: true,
      currentCompany: true,
    });
    this.store = store;
  }

  load(items: ICompany[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Company(this.store, item))
      );
    });
  }

  loadCurrentCompany(item: ICompany) {
    runInAction(() => {
      this.currentCompany = new Company(this.store, item);
    });
  }

  removeCurrentCompany() {
    runInAction(() => {
      this.currentCompany = null;
    });
  }

  get all() {
    const companies = Array.from(this.items.values());
    const filtered = companies.filter(
      (company) => company.companyProfile.companyReg !== "namcor"
    );
    return filtered;
  }
}
