import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import ISalesTrackerItem from "../types/ISalesTrackerItem";
import AppApi from "./AppApi";

export default class ReportSalesTrackerApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, limit = 10000) {
    if (!token) this.store.order.removeAll();
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .readAll<ISalesTrackerItem>({
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();

    this.store.report.salesTracker.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getMonthlySales(month: number, year: number) {
    const firstDateOfTheMonthInMillis = this.getFirstDateOfTheMonthInMillis(
      month,
      year
    );
    const lastDateOfTheMonthInMillis = this.getLastDateOfTheMonthInMillis(
      month,
      year
    );

    return this.getMontlySales(
      firstDateOfTheMonthInMillis,
      lastDateOfTheMonthInMillis
    );
  }

  // Get monthly sales.
  private async getMontlySales(
    firstDateOfTheMonthInMillis: number,
    lastDateOfTheMonthInMillis: number
  ) {
    // Remove all items from store
    this.store.report.salesTracker.removeAll();

    const querySpec = {
      query: `SELECT * FROM c 
              WHERE c.createdOn > @firstDate 
              AND 
              c.createdOn < @lastDate`,
      parameters: [
        {
          name: "@firstDate",
          value: firstDateOfTheMonthInMillis,
        },
        {
          name: "@lastDate",
          value: lastDateOfTheMonthInMillis,
        },
      ],
    };

    const { resources: items } = await this.dbContainer.items
      .query<ISalesTrackerItem>(querySpec)
      .fetchNext();
    this.store.report.salesTracker.load(items);
    return items;
  }

  // <Create item>
  async create(item: ISalesTrackerItem) {
    try {
      const { resource: createdItem } = await this.dbContainer.items.create(
        item
      );
      if (createdItem) this.store.report.salesTracker.load([createdItem]);
      return { existingResource: false };
    } catch (error) {
      return this.handle409(error);
    }
  }

  handle409(err: any) {
    if (err.code && err.code === 409) {
      return { existingResource: true };
    } else {
      throw err;
    }
  }

  // <Update item>
  async update(item: ISalesTrackerItem) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.report.salesTracker.load([updatedItem]);
  }

  // <Delete item>
  async delete(item: ISalesTrackerItem) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    this.store.report.salesTracker.remove(item.id); // Remove from memory
    return result;
  }

  // Month first day timestamp
  private getFirstDateOfTheMonthInMillis(month: number, year: number) {
    return new Date(year, month, 1).getTime();
  }
  // Month last day timestamp
  private getLastDateOfTheMonthInMillis(month: number, year: number) {
    return new Date(year, month + 1, 0).getTime();
  }
}
