import { makeObservable } from "mobx";
import AppStore from "./AppStore";
import SalesTrackerStore from "./SalesTrackerStore";

export default class ReportStore {
  salesTracker: SalesTrackerStore;

  constructor(store: AppStore) {
    makeObservable(this, {
      salesTracker: true,
    });

    this.salesTracker = new SalesTrackerStore(store);
  }
}
