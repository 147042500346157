import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import IFuel from "../types/IFuel";
import AppApi from "./AppApi";

export default class FuelApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, limit = 20) {
    if (!token) this.store.order.removeAll();
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .readAll<IFuel>({
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();

    this.store.fuel.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getByAvailability(availability: boolean, token?: string, limit = 20) {
    if (!token) this.store.fuel.removeAll();
    const querySpec = {
      query: "SELECT * FROM c WHERE c.isAvailable = @availability",
      parameters: [
        {
          name: "@availability",
          value: availability,
        },
      ],
    };
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<IFuel>(querySpec, { maxItemCount: limit })
      .fetchNext();

    this.store.fuel.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getByWarehouse(warehouse: string, token?: string, limit = 20) {
    if (!token) this.store.fuel.removeAll();
    const querySpec = {
      query: "SELECT * FROM c WHERE c.warehouse = @warehouse",
      parameters: [
        {
          name: "@warehouse",
          value: warehouse,
        },
      ],
    };
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<IFuel>(querySpec, { maxItemCount: limit })
      .fetchNext();

    this.store.fuel.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string) {
    const { resource: item } = await this.dbContainer.item(id).read<IFuel>();
    if (item) this.store.fuel.load([item]);
  }

  // </CreateItem>
  async create(item: IFuel) {
    const { resource: createdItem } = await this.dbContainer.items.create(item);
    if (createdItem) this.store.fuel.load([createdItem]);
  }

  // </UpdateItem>
  async update(item: IFuel) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.fuel.load([updatedItem]);
  }

  // </DeleteItem>
  async delete(item: IFuel) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    console.log("Result: ", result);

    this.store.fuel.remove(item.id); // Remove from memory
  }
}
