import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import { BlobItemModel } from "../models/BlobItem";
import IBlobItem from "../types/IBlobItem";

export default class FileViewerStore {
  selected: BlobItemModel | null = null;
  protected store: AppStore;

  constructor(store: AppStore) {
    this.store = store;
    makeObservable(this, {
      selected: true,
    });
    this.store = store;
  }

  select(item: IBlobItem) {
    runInAction(() => {
      this.selected = new BlobItemModel(this.store, item);
    });
  }

  clearSelected() {
    runInAction(() => {
      this.selected = null;
    });
  }
}
