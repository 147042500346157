import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

// UIKIT CSS
// import "../node_modules/uikit/dist/css/uikit-core.min.css";
// import "../node_modules/uikit/dist/css/uikit.min.css";

// UIKIT JS
// import "../node_modules/uikit/dist/js/uikit.min.js";
// import "../node_modules/uikit/dist/js/uikit-icons.min.js";

// UIKIT ICONS
// import * as UIkit from "uikit";
// import * as Icons from "uikit/dist/js/uikit-icons";
// UIkit.use(Icons);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
