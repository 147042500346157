import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import { CartLube } from "../models/CartLube";
import ICartLube from "../types/ICartLube";

export default class CartLubeStore extends Store<ICartLube, CartLube> {
  items = new Map<string, CartLube>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: ICartLube[]) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new CartLube(this.store, item))
      );
    });
  }
}
