import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import {
  IFuelDashboardData,
  ILubeDashboardData,
  IOrderDashboardData,
  IPricingDashboardData,
} from "../types/IDashboard";
import AppApi from "./AppApi";

export default class DashboardApi {
  lubesDBContainer: Container;
  fuelsDBContainer: Container;
  pricingDBContainer: Container;
  ordersDBContainer: Container;

  constructor(
    private api: AppApi,
    private store: AppStore,
    containers: {
      lubesDBContainer: Container;
      fuelsDBContainer: Container;
      pricingDBContainer: Container;
      ordersDBContainer: Container;
    }
  ) {
    this.lubesDBContainer = containers.lubesDBContainer;
    this.fuelsDBContainer = containers.fuelsDBContainer;
    this.pricingDBContainer = containers.pricingDBContainer;
    this.ordersDBContainer = containers.ordersDBContainer;
  }

  //  ============================================================================================
  //  =========================== Get current month's orders - analytics =========================
  async getOrdersAnalytics(companyReg?: string) {
    const active = await this.getActiveOrdersCount(companyReg);
    const successful = await this.getSuccessfulOrdersCount(companyReg);
    const unsuccessful = await this.getUnsuccessfulOrdersCount(companyReg);
    const all = active + successful + unsuccessful;

    const data: IOrderDashboardData = { all, active, successful, unsuccessful };
    // this.store.cartFuel.load(items);
    return data;
  }
  private async getActiveOrdersCount(companyReg?: string) {
    const querySpec = {
      query: companyReg
        ? `SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.activeStage!='Closed' AND c.orderSummary.customerId='${companyReg}'`
        : "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.activeStage!='Closed'",
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec, { partitionKey: "Order" })
      .fetchAll();
    return count[0];
  }
  private async getSuccessfulOrdersCount(companyReg?: string) {
    const querySpec = {
      query: companyReg
        ? `SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isSuccessful=true AND c.orderSummary.customerId='${companyReg}'`
        : "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isSuccessful=true",
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec, { partitionKey: "Order" })
      .fetchAll();
    return count[0];
  }
  private async getUnsuccessfulOrdersCount(companyReg?: string) {
    const querySpec = {
      query: companyReg
        ? `SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.activeStage='Closed' AND c.orderSummary.isSuccessful=false AND c.orderSummary.customerId='${companyReg}'`
        : "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.activeStage='Closed' AND c.orderSummary.isSuccessful=false",
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec, { partitionKey: "Order" })
      .fetchAll();
    return count[0];
  }

  //  ============================================================================================
  //  =============================== Get pending orders - analytics =============================
  async getPendingOrdersAnalytics() {
    // this.store.cartFuel.removeAll();
    const requests = await this.getRequestsCount("34343");
    const quotations = await this.getQuotesCount("34343");
    const purchaseOrders = await this.getPurchaseOrdersCount("34343");
    const salesOrders = await this.getSalesOrdersCount("34343");
    const goodsReceivedNote = await this.getGRNCount("34343");
    const invoice = await this.getInvoicesCount("34343");
    const proofOfPayment = await this.getPOPCount("34343");

    const data = {
      requests,
      quotations,
      purchaseOrders,
      salesOrders,
      goodsReceivedNote,
      invoice,
      proofOfPayment,
    };
    // this.store.cartFuel.load(items);
    return data;
  }
  private async getQuotesCount(stage: string, customerId?: string) {
    const querySpec = {
      query:
        "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.activeStage=@stage",
      parameters: [
        {
          name: "@stage",
          value: stage,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getPurchaseOrdersCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getSalesOrdersCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getGRNCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getInvoicesCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getPOPCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getRequestsCount(customerId: string) {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.orderSummary.isActive=true",
      parameters: [
        {
          name: "@customerId",
          value: customerId,
        },
      ],
    };
    const { resources: count } = await this.ordersDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }

  //  ============================================================================================
  //  ============================= Get products inventory - analytics ===========================
  // Lubes
  async getLubesAnalytics() {
    // this.store.cartFuel.removeAll();
    const available = await this.getAvailableLubesCount();
    const outOfStock = await this.getOutOfStockLubesCount();

    const data: ILubeDashboardData = { available, outOfStock };
    // this.store.cartFuel.load(items);
    return data;
  }
  private async getAvailableLubesCount() {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE  c.isAvailable = true",
    };
    const { resources: count } = await this.lubesDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getOutOfStockLubesCount() {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE  c.isAvailable = false",
    };
    const { resources: count } = await this.lubesDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  async getFuelsAnalytics() {
    // this.store.cartFuel.removeAll();
    const availableSum = await this.getAvailableFuelsSum();
    const outOfStockSum = await this.getOutOfStockFuelsSum();
    const total = availableSum + outOfStockSum;
    const available = (availableSum / total) * 100;
    const outOfStock = (outOfStockSum / total) * 100;

    const data: IFuelDashboardData = { available, outOfStock };
    // this.store.cartFuel.load(items);
    return data;
  }
  private async getAvailableFuelsSum() {
    const querySpec = {
      query: "SELECT VALUE SUM(c.available) FROM c",
    };
    const { resources: count } = await this.fuelsDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getOutOfStockFuelsSum() {
    const querySpec = {
      query: "SELECT VALUE SUM(c.outOfStock) FROM c",
    };
    const { resources: count } = await this.fuelsDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  async getPricingReviewsAnalytics() {
    // this.store.cartFuel.removeAll();
    const reviewed = await this.getReviewedPricingCount();
    const awaitingReviewal = await this.getNotReviewedPricingCount();
    // this.store.cartFuel.load(items);

    const data: IPricingDashboardData = { reviewed, awaitingReviewal };
    return data;
  }
  private async getReviewedPricingCount() {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.isReviewed=true",
    };
    const { resources: count } = await this.pricingDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
  private async getNotReviewedPricingCount() {
    const querySpec = {
      query: "SELECT VALUE COUNT(1) FROM c WHERE c.isReviewed=false",
    };
    const { resources: count } = await this.pricingDBContainer.items
      .query<number>(querySpec)
      .fetchAll();
    return count[0];
  }
}
