import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IOrder, {
  IOrderClosedOrder,
  IOrderGoodsReceivedNote,
  IOrderInvoice,
  IOrderProofOfPayment,
  IOrderPurchaseOrder,
  IOrderQuotation,
  IOrderRequest,
  IOrderSalesOrder,
  IOrderSummary,
} from "../types/IOrder";

export class Order implements IOrder {
  id: string;
  type: "Order" = "Order";
  backordersIds: string[];
  orderSummary: IOrderSummary;
  request: IOrderRequest;
  quotation: IOrderQuotation;
  purchaseOrder: IOrderPurchaseOrder;
  salesOrder: IOrderSalesOrder;
  goodsReceivedNote: IOrderGoodsReceivedNote;
  invoice: IOrderInvoice;
  proofOfPayment: IOrderProofOfPayment;
  closedOrder: IOrderClosedOrder;

  constructor(private store: AppStore, order: IOrder) {
    makeAutoObservable(this);
    this.id = order.id;
    this.orderSummary = order.orderSummary;
    this.request = order.request;
    this.quotation = order.quotation;
    this.purchaseOrder = order.purchaseOrder;
    this.salesOrder = order.salesOrder;
    this.goodsReceivedNote = order.goodsReceivedNote;
    this.invoice = order.invoice;
    this.proofOfPayment = order.proofOfPayment;
    this.closedOrder = order.closedOrder;
    this.backordersIds = order.backordersIds;
  }

  get asJson(): IOrder {
    return {
      id: this.id,
      type: "Order",
      orderSummary: this.orderSummary,
      request: this.request,
      quotation: this.quotation,
      purchaseOrder: this.purchaseOrder,
      salesOrder: this.salesOrder,
      goodsReceivedNote: this.goodsReceivedNote,
      invoice: this.invoice,
      proofOfPayment: this.proofOfPayment,
      closedOrder: this.closedOrder,
      backordersIds: this.backordersIds,
    };
  }
}
