import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signOut,
  deleteUser,
  onAuthStateChanged,
  User,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { DB } from "../database/Variables";
import { db, auth, authWorker } from "../database/FirebaseConfig";
import AppStore from "../stores/AppStore";
import { ICompanyProfile } from "../types/ICompany";
import { IUser } from "../types/IUser";
import AppApi from "./AppApi";

export default class AuthApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.onAuthChanged();
  }

  onAuthChanged() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in.
        await this.onSignedIn(user.uid);
      } else {
        // User is signed out
        await this.onSignedOut();
      }
    });
  }

  private async onSignedIn(uid: string) {
    this.store.user.loading = true;
    this.store.company.loading = true;

    // Get user doc
    const userDocSnap = await getDoc(doc(db, DB.users, uid));
    // Dont authorize if user doesn't exists.
    if (!userDocSnap.exists()) {
      this.store.user.loading = false;
      this.store.company.loading = false;
      return;
    }
    const user = { uid: userDocSnap.id, ...userDocSnap.data() } as IUser;
    await this.store.user.loadCurrentUser(user);
    this.store.user.loading = false;

    // Get company doc
    const companyReg = user.companyReg;
    await this.api.company.getByReg(companyReg); // Cause of error, sometimes azure throws an error.
    const company = await this.store.company.items.get(companyReg);

    // Dont authorize if company doesn't exists.
    if (!company) {
      this.store.company.loading = false;
      return;
    }
    await this.store.company.loadCurrentCompany(company);

    // Stop loading
    this.store.company.loading = false;
  }

  private async onSignedOut() {
    this.store.user.loading = true;
    this.store.company.loading = true;
    await this.store.user.removeCurrentUser();
    await this.store.company.removeCurrentCompany();
    this.store.user.loading = false;
    this.store.company.loading = false;
  }

  async companyProfileExistence(companyReg: string) {
    const docRef = doc(db, DB.companies, `reg_${companyReg}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return true;
    else return false;
  }

  async createCompanyProfile(companyProfile: ICompanyProfile) {
    const reg = companyProfile.companyReg;
    const ref = doc(db, DB.companies, `reg_${reg}`);
    await setDoc(ref, {
      name: companyProfile.companyName,
    });
  }

  // User will be created with authWorker, thus not signed-in
  async createUser(user: IUser, password?: string) {
    const { email } = user;
    // password = `${user.firstName}@${user.lastName}`;
    password = "123456";

    const userCredential = await createUserWithEmailAndPassword(
      authWorker,
      email,
      password
    ).catch((error) => {
      console.log(error);

      // console.log("Error creating user");
      return null;
    });

    if (!userCredential) return null;

    user.uid = userCredential.user.uid;
    await setDoc(doc(db, DB.users, user.uid), user);
    await signOut(authWorker);
    return user;
  }

  async updateUser(user: IUser) {
    await setDoc(doc(db, DB.users, user.uid), user);
    return user;
  }

  //   User will be created and signed-in
  async onSignup(user: IUser, password?: string) {
    const { email } = user;
    password = `${user.firstName}@${user.lastName}`;

    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      return null;
    });

    if (userCredential) {
      user.uid = userCredential.user.uid;
      await setDoc(doc(db, DB.users, user.uid), user);
    }
    return userCredential;
  }

  async signInuser(email: string, password: string) {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        return null;
      });

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      console.log("Error signing in", error);

      return null;
    });

    if (userCredential) return userCredential.user;

    return userCredential;
  }

  async signOutUser() {
    signOut(auth);
  }

  async removeUser(user: User) {
    await deleteUser(user);
    await this.deleteUserFromDB(user.uid);
    return;
  }

  async passwordResetWithEmail(email: string) {
    await sendPasswordResetEmail(auth, email)
      .then(function () {
        alert("Password reset email sent.");
      })
      .catch(function (error) {
        // An error happened.
        alert("Could not send email.");
      });
  }

  async passwordResetWithOldPassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    const credential = EmailAuthProvider.credential(email, oldPassword);
    const user = auth.currentUser;
    if (!user) return;
    await reauthenticateWithCredential(user, credential)
      .then(() => {
        if (newPassword.length >= 6)
          // User re-authenticated.
          updatePassword(user, newPassword)
            .then(function () {
              // Update successful.
              alert("Password reset successfully");
            })
            .catch(function (error) {
              // An error happened.
              alert("Could not reset password");
            });
        else alert("Password should be atleast 6 characters long");
      })
      .catch((error) => {
        // An error happened.
        alert("Incorrect password");
      });
  }

  async loadUser(uid: string) {
    const docRef = doc(db, DB.users, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const user = { uid: docSnap.id, ...docSnap.data() } as IUser;
      await this.store.user.load([user]);
      return user;
    } else return undefined;
  }

  async deleteUserFromDB(uid: string) {
    const docRef = doc(db, DB.users, uid);
    await deleteDoc(docRef);
  }

  async loadCompanyUsers(reg: string) {
    await this.store.user.removeAll();
    const $query = query(
      collection(db, DB.users),
      where("companyReg", "==", reg)
    );
    const querySnapshot = await getDocs($query);
    const users = querySnapshot.docs.map((doc) => {
      return { uid: doc.id, ...doc.data() } as IUser;
    });
    await this.store.user.load(users);
  }
}
