import { Container, PatchOperation, SqlQuerySpec } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import IBlobItem from "../types/IBlobItem";
import IOrder from "../types/IOrder";
import AppApi from "./AppApi";
import uploadFileToBlob, {
  deleteFileFromBlob,
  isStorageConfigured,
} from "./FileUploadApi";

export default class OrderApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, customerId?: string, limit = 10) {
    if (!token) this.store.order.removeAll();
    let querySpec: string | SqlQuerySpec = "";

    if (customerId === "namcor")
      querySpec = {
        query: "SELECT * FROM c ORDER BY c.orderSummary.createdOn DESC",
      };

    if (customerId && customerId !== "namcor") {
      querySpec = {
        query:
          "SELECT * FROM c WHERE c.orderSummary['customerId'] = @customerId ORDER BY c.orderSummary.createdOn DESC",
        parameters: [
          {
            name: "@customerId",
            value: customerId,
          },
        ],
      };
    }

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<IOrder>(querySpec, {
        maxItemCount: limit,
        partitionKey: "Order",
        continuationToken: token,
      })
      .fetchNext();
    this.store.order.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getByStage(
    stage: string,
    token?: string,
    customerId?: string,
    limit = 10
  ) {
    if (!token) this.store.order.removeAll();

    let querySpec: string | SqlQuerySpec = "";

    if (customerId === "namcor")
      querySpec = {
        query:
          "SELECT * FROM c WHERE  c.orderSummary.activeStage = @stage ORDER BY c.orderSummary.createdOn",
        parameters: [
          {
            name: "@stage",
            value: stage,
          },
        ],
      };

    if (customerId && customerId !== "namcor") {
      querySpec = {
        query:
          "SELECT * FROM c WHERE c.orderSummary['customerId'] = @customerId AND c.orderSummary['activeStage'] = @stage ORDER BY c.orderSummary.createdOn DESC",
        parameters: [
          {
            name: "@stage",
            value: stage,
          },
          {
            name: "@customerId",
            value: customerId,
          },
        ],
      };
    }

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<IOrder>(querySpec, {
        maxItemCount: limit,
        partitionKey: "Order",
        continuationToken: token,
      })
      .fetchNext();
    this.store.order.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string, customerId?: string) {
    let querySpec: string | SqlQuerySpec = "";

    if (customerId === "namcor")
      querySpec = {
        query: "SELECT * FROM c WHERE  c.id = @id",
        parameters: [
          {
            name: "@id",
            value: id,
          },
        ],
      };

    if (customerId && customerId !== "namcor") {
      querySpec = {
        query:
          "SELECT * FROM c WHERE c.orderSummary['customerId'] = @customerId AND c.id = @id",
        parameters: [
          {
            name: "@id",
            value: id,
          },
          {
            name: "@customerId",
            value: customerId,
          },
        ],
      };
    }

    const { resources: items } = await this.dbContainer.items
      .query<IOrder>(querySpec)
      .fetchNext();
    this.store.order.load(items);

    return items;
  }

  // <Create item>
  async create(item: IOrder) {
    try {
      const { resource: createdItem } = await this.dbContainer.items.create(
        item
      );
      if (createdItem) this.store.order.load([createdItem]);
      return { existingResource: false };
    } catch (error) {
      return this.handle409(error);
    }
  }

  handle409(err: any) {
    if (err.code && err.code === 409) {
      return { existingResource: true };
    } else {
      throw err;
    }
  }

  // <Update item>
  async update(item: IOrder) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.type)
      .replace(item);
    if (updatedItem) this.store.order.load([updatedItem]);
  }

  // <Patch batchorder item>
  async patchBackorders(orderId: string, value: string[]) {
    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: "/backordersIds",
        value: value,
      },
    ];

    try {
      const { resource: result } = await this.dbContainer
        .item(orderId, "Order")
        .patch(operations);
      if (result) this.store.order.load([result]);
    } catch (error) {
      console.log(error);
    }
  }

  // Link backorders
  async unlinkMultipleBackorders(unlinkedOrders: string[]) {
    unlinkedOrders.forEach((unlinked) => {
      this.patchBackorders(unlinked, []);
    });
  }

  // Unlink backorders
  async linkMultipleBackorders(linkedOrders: string[]) {
    linkedOrders.forEach((linked) => {
      this.patchBackorders(linked, linkedOrders);
    });
  }

  // <Delete item>
  async delete(item: IOrder) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.type)
      .delete(); // Remove from database
    this.store.order.remove(item.id); // Remove from memory
    return result;
  }

  // <Upload quote to blob>
  async uploadFileToQuotationBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/quotation`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.quotation.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete quote from blob>
  async deleteFileFromQuotationBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/quotation`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.quotation.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload po to blob>
  async uploadFileToPurchaseOrderBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/po`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.purchaseOrder.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete purchase order from blob>
  async deleteFileFromPurchaseOrderBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/po`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.purchaseOrder.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload so to blob>
  async uploadFileToSalesOrderBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/so`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.salesOrder.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete purchase order from blob>
  async deleteFileFromSalesOrderBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/so`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.salesOrder.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload grn to blob>
  async uploadFileToGoodsReceivedNoteBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/grn`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.goodsReceivedNote.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete purchase order from blob>
  async deleteFileFromGoodsReceivednoteBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/grn`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.goodsReceivedNote.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload invoice to blob>
  async uploadFileToInvoiceBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/invoice`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.invoice.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete purchase order from blob>
  async deleteFileFromInvoiceBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/invoice`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.invoice.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // <Upload POP to blob>
  async uploadFileToProofOfPaymentBlob(
    id: string,
    file: File | null
  ): Promise<IBlobItem[]> {
    if (!file) return [];
    const containerName = "orders";
    const prefix = `order_${id}/pop`;

    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await uploadFileToBlob(
        file,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.proofOfPayment.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }
  // <Delete purchase order from blob>
  async deleteFileFromProofOfPaymentBlob(
    id: string,
    fileName: string
  ): Promise<IBlobItem[]> {
    if (!fileName) return [];
    const containerName = "orders";
    const prefix = `order_${id}/pop`;
    // Upload file to storage
    if (isStorageConfigured()) {
      const blobsInContainer = await deleteFileFromBlob(
        fileName,
        containerName,
        prefix
      );
      // Update db
      const localOrder = this.store.order.items.get(id);
      if (localOrder) {
        localOrder.proofOfPayment.attachments = blobsInContainer;
        await this.update(localOrder.asJson);
      }
      //Return the blobsInContainer
      return blobsInContainer;
    } else return [];
  }

  // async uploadProgress(file: File | null): Promise<IBlobItem[]> {
  //   if (!file) return [];
  //   // Upload file to storage
  //   if (isStorageConfigured()) {
  //     const blobsInContainer = uploadFileToBlob(file);

  //     //Return the blobsInContainer
  //     return blobsInContainer;
  //   }
  //   return [];
  // }
}
