import { makeAutoObservable } from "mobx";
import IFuel from "../types/IFuel";
import AppStore from "../stores/AppStore";

export class Fuel implements IFuel {
  id: string;
  name: string;
  description: string;
  available: number;
  warehouse: string;
  createdOn: number;

  constructor(private store: AppStore, fuel: IFuel) {
    makeAutoObservable(this);
    this.id = fuel.id;
    this.name = fuel.name;
    this.description = fuel.description;
    this.warehouse = fuel.warehouse;
    this.available = fuel.available;
    this.createdOn = fuel.createdOn;
  }

  get asJson() {
    const fuel: IFuel = {
      id: this.id,
      name: this.name,
      description: this.description,
      available: this.available,
      warehouse: this.warehouse,
      createdOn: Date.now(),
    };
    return fuel;
  }
}
