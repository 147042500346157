import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import {
  IUserRole,
  ILubeRole,
  IFuelRole,
  IFuelPricingRole,
  ITrackingRole,
  ICustomerRole,
  ITeamRole,
  IOrderRole,
} from "../types/IUserRole";

export class UserRole implements IUserRole {
  id: string;
  roleName: string;
  lube: ILubeRole;
  fuel: IFuelRole;
  fuelPricing: IFuelPricingRole;
  tracking: ITrackingRole;
  customer: ICustomerRole;
  team: ITeamRole;
  order: IOrderRole;

  constructor(private store: AppStore, role: IUserRole) {
    makeAutoObservable(this);
    this.id = role.id;
    this.roleName = role.roleName;
    this.lube = role.lube;
    this.fuel = role.fuel;
    this.customer = role.customer;
    this.fuelPricing = role.fuelPricing;
    this.tracking = role.tracking;
    this.team = role.team;
    this.order = role.order;
  }

  get asJson(): IUserRole {
    return {
      id: this.id,
      roleName: this.roleName,
      lube: this.lube,
      fuel: this.fuel,
      fuelPricing: this.fuelPricing,
      tracking: this.tracking,
      customer: this.customer,
      team: this.team,
      order: this.order,
    };
  }
}
