import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import ReportOrderDashboardApi from "./ReportOrderDashboardApi";
import ReportSalesTrackerApi from "./ReportSalesTrackerApi";

export default class ReportApi {
  ordersApi: ReportOrderDashboardApi;
  salesTrackerApi: ReportSalesTrackerApi;

  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    // dashboard reports.
    this.ordersApi = new ReportOrderDashboardApi(api, store, dbContainer);
    // sales reports.
    this.salesTrackerApi = new ReportSalesTrackerApi(api, store, dbContainer);
  }
}
