import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ICartFuel from "../types/ICartFuel";

export class CartFuel implements ICartFuel {
  id: string;
  customerId: string;
  warehouse: string;
  productId: string;
  name: string;
  description: string;
  quantity: number;
  isSaved: boolean;

  constructor(private store: AppStore, fuel: ICartFuel) {
    makeAutoObservable(this);
    this.id = fuel.id;
    this.name = fuel.name;
    this.description = fuel.description;
    this.warehouse = fuel.warehouse;
    this.productId = fuel.productId;
    this.customerId = fuel.customerId;
    this.quantity = fuel.quantity;
    this.isSaved = true;
  }

  get asJson(): ICartFuel {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      customerId: this.customerId,
      quantity: this.quantity,
      productId: this.productId,
      warehouse: this.warehouse,
    };
  }
}
