import { Container, SqlQuerySpec } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import ICartFuel from "../types/ICartFuel";
import AppApi from "./AppApi";

export default class CartFuelApi {
  dbContainer: Container;

  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, customerId?: string, limit = 100) {
    if (!token) this.store.cartFuel.removeAll();
    let querySpec: string | SqlQuerySpec = "";

    if (customerId) {
      querySpec = {
        query: "SELECT * FROM c WHERE  c.customerId = @customerId",
        parameters: [
          {
            name: "@customerId",
            value: customerId,
          },
        ],
      };
    }

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ICartFuel>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchNext();
    this.store.cartFuel.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string) {
    const { resource: item } = await this.dbContainer
      .item(id)
      .read<ICartFuel>();
    if (item) this.store.cartFuel.load([item]);
  }

  async create(item: ICartFuel) {
    const { resource: createdItem } = await this.dbContainer.items.create(item);
    if (createdItem) this.store.cartFuel.load([createdItem]);
  }

  async update(item: ICartFuel) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.customerId)
      .replace(item);
    if (updatedItem) this.store.cartFuel.load([updatedItem]);
  }

  async delete(item: ICartFuel) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.customerId)
      .delete(); // Remove from database
    this.store.cartFuel.remove(item.id); // Remove from memory
    return result;
  }
}
