import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import { IUser } from "../types/IUser";
import { IUserRole } from "../types/IUserRole";

export class User implements IUser {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  companyReg: string;
  companyName: string;
  role: IUserRole;

  constructor(private store: AppStore, user: IUser) {
    makeAutoObservable(this);
    this.uid = user.uid;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.companyReg = user.companyReg;
    this.companyName = user.companyName;
    this.role = user.role;
  }

  get asJson(): IUser {
    return {
      uid: this.uid,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      companyReg: this.companyReg,
      companyName: this.companyName,
      role: this.role,
    };
  }
}
