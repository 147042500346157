import Store from "./Store";
import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";
import ITracking from "../types/ITracking";
import { Tracking } from "../models/Tracking";

export default class TrackingStore extends Store<ITracking, Tracking> {
  items = new Map<string, Tracking>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      items: true,
      selected: true,
    });
    this.store = store;
  }

  load(items: ITracking[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Tracking(this.store, item))
      );
    });
  }
}
