import { Container, PatchOperation, SqlQuerySpec } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import IOrder from "../types/IOrder";
import ITracking, { defaultTracking } from "../types/ITracking";
import AppApi from "./AppApi";

export default class TrackingApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  // <Get all items / first page>
  async getAll(stage: string, token?: string, customerId?: string, limit = 10) {
    if (!token) this.store.track.removeAll();
    let querySpec: string | SqlQuerySpec = "";

    if (customerId === "namcor")
      querySpec = {
        query: "SELECT * FROM c WHERE c.status['value'] != @stage",
        parameters: [
          {
            name: "@stage",
            value: stage,
          },
        ],
      };

    if (customerId && customerId !== "namcor") {
      querySpec = {
        query:
          "SELECT * FROM c WHERE c.customerId = @customerId AND c.status['value'] != @stage",
        parameters: [
          {
            name: "@customerId",
            value: customerId,
          },
          {
            name: "@stage",
            value: stage,
          },
        ],
      };
    }

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ITracking>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
        // partitionKey: "Tracking",
      })
      .fetchNext();
    this.store.track.load(items);
    return { hasMoreResults, continuationToken };
  }

  // <Get by availability status>
  async getByStage(
    stage: string,
    customerId?: string,
    token?: string,
    limit = 10
  ) {
    if (!token) this.store.track.removeAll();

    // Get orders per customerId
    let querySpec: string | SqlQuerySpec = "";

    if (customerId === "namcor")
      querySpec = {
        query: "SELECT * FROM c WHERE c.status['value'] = @stage",
        parameters: [
          {
            name: "@stage",
            value: stage,
          },
        ],
      };

    if (customerId && customerId !== "namcor") {
      querySpec = {
        query:
          "SELECT * FROM c WHERE c.status['value'] = @stage AND c.customerId = @customerId",
        parameters: [
          {
            name: "@stage",
            value: stage,
          },
          {
            name: "@customerId",
            value: customerId,
          },
        ],
      };
    }

    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .query<ITracking>(querySpec, {
        maxItemCount: limit,
        continuationToken: token,
        // partitionKey: "Tracking",
      })
      .fetchNext();
    this.store.track.load(items);
    return { hasMoreResults, continuationToken };
  }

  // <Get by ID>
  async getById(id: string) {
    const { resource: item } = await this.dbContainer
      .item(id, id)
      .read<ITracking>();
    if (item) this.store.track.load([item]);
  }

  // <Create item>
  async create(item: ITracking) {
    try {
      const { resource: createdItem } = await this.dbContainer.items.create(
        item
      );
      if (createdItem) this.store.track.load([createdItem]);
      return { existingResource: false };
    } catch (error) {
      return this.handle409(error);
    }
  }

  // <Create item>
  async createTrackingFromOrder(item: IOrder, trackingCode: string) {
    try {
      const trackingItem = defaultTracking;
      trackingItem.id = trackingCode;
      trackingItem.orderId = item.id;
      trackingItem.customerId = item.orderSummary.customerId;
      trackingItem.customerName = item.orderSummary.customerName;

      const { resource: createdItem } = await this.dbContainer.items.create(
        trackingItem
      );
      if (createdItem) this.store.track.load([trackingItem]);
      return { existingResource: false };
    } catch (error) {
      return this.handle409(error);
    }
  }

  handle409(err: any) {
    if (err.code && err.code === 409) {
      return { existingResource: true };
    } else {
      throw err;
    }
  }

  async archive(id: string, value: string = "Archived") {
    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: "/status/value",
        value: value,
      },
    ];

    try {
      const { resource: result } = await this.dbContainer
        .item(id, id)
        .patch(operations);
      if (result) this.store.track.load([result]);
    } catch (error) {
      console.log(error);
    }
  }

  // <Update item>
  async update(item: ITracking) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.track.load([updatedItem]);
  }

  // <Delete item>
  async delete(item: ITracking) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    this.store.track.remove(item.id); // Remove from memory
    return result;
  }

  async deleteFromId(id: string) {
    const { resource: result } = await this.dbContainer.item(id, id).delete(); // Remove from database
    this.store.track.remove(id); // Remove from memory
    return result;
  }
}
