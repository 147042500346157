import { Container } from "@azure/cosmos";
import AppStore from "../stores/AppStore";
import { IUserRole } from "../types/IUserRole";
import AppApi from "./AppApi";

export default class UserRoleApi {
  dbContainer: Container;
  constructor(
    private api: AppApi,
    private store: AppStore,
    dbContainer: Container
  ) {
    this.dbContainer = dbContainer;
  }

  async getAll(token?: string, limit = 100) {
    if (!token) this.store.userRole.removeAll();
    const {
      resources: items,
      continuationToken,
      hasMoreResults,
    } = await this.dbContainer.items
      .readAll<IUserRole>({
        maxItemCount: limit,
        continuationToken: token,
      })
      .fetchAll();

    this.store.userRole.load(items);
    return { hasMoreResults, continuationToken };
  }

  async getById(id: string) {
    const { resource: item } = await this.dbContainer
      .item(id)
      .read<IUserRole>();

    if (item) this.store.userRole.load([item]);
  }

  // </CreateItem>
  async create(item: IUserRole) {
    const { resource: createdItem } = await this.dbContainer.items.create(item);
    if (createdItem) this.store.userRole.load([createdItem]);
  }

  // </UpdateItem>
  async update(item: IUserRole) {
    const { resource: updatedItem } = await this.dbContainer
      .item(item.id, item.id)
      .replace(item);
    if (updatedItem) this.store.userRole.load([updatedItem]);
  }

  // </DeleteItem>
  async delete(item: IUserRole) {
    const { resource: result } = await this.dbContainer
      .item(item.id, item.id)
      .delete(); // Remove from database
    console.log("Result: ", result);

    this.store.userRole.remove(item.id); // Remove from memory
  }
}
