import { observer } from "mobx-react-lite";
import { Route, Redirect, useLocation } from "react-router-dom";
import { LoadingEllipsis } from "../components/Loading";
import { useAppContext } from "../functions/Context";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const PrivateRoute = observer((props: any) => {
  const { children, type, ...rest } = props;
  const { store } = useAppContext();
  const location = useLocation();
  const user = store.user.currentUser;
  const isLoading = store.user.loading;
  // companyReg= user?.companyReg;

  const redirectToSignIn = (
    <Redirect
      to={{
        pathname: "/sign-in",
        state: { from: location },
      }}
    />
  );

  const redirectToAdmin = (
    <Redirect
      to={{
        pathname: "/a",
      }}
    />
  );

  const redirectToClient = (
    <Redirect
      to={{
        pathname: "/c",
      }}
    />
  );

  const authConditions = () => {
    if (user) {
      // Admin users
      if (user.companyReg === "namcor")
        return type === "namcor" ? children : redirectToAdmin;

      // Client users
      if (user.companyReg !== "namcor" && user.companyReg)
        return type === "client" ? children : redirectToClient;

      // Unkown users
      return redirectToSignIn;
    } else {
      // Loading
      if (isLoading) {
        return (
          <div style={style}>
            <LoadingEllipsis />
          </div>
        );
      } else {
        // Redirect to loading
        return redirectToSignIn;
      }
    }
  };

  return (
    <Route {...rest}>
      {/* {user ? (
        children
      ) : isLoading ? (
        <div style={style}>
          <LoadingEllipsis />
        </div>
      ) : (
        redirectToSignIn
      )} */}
      {authConditions()}
    </Route>
  );
});

export default PrivateRoute;
